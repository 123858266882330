import React from 'react';

function ContactPage() {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>Contact information will be provided here.</p>
    </div>
  );
}

export default ContactPage;
