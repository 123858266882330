import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ExpeditionSelector.css';

function ExpeditionSelector() {
  const navigate = useNavigate();

  return (
    <div className="background-container">
      <div 
        className="expedition-selector expedition-1" 
        onClick={() => navigate('/gallery/exp1')}
      >
        <p>Expedition 1</p>
      </div>
      <div 
        className="expedition-selector expedition-2" 
        onClick={() => navigate('/gallery/exp2')}
      >
        <p>Expedition 2</p>
      </div>
    </div>
  );
}

export default ExpeditionSelector;
