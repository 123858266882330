// GalleryWindow.js
import React, { useState } from 'react';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-responsive-modal';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'react-responsive-modal/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const GalleryWindow = ({ images }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const settings = {
    dots: images.length > 1,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const openModal = (image, event) => {
    event.preventDefault();
    setSelectedImage(image);
    setModalOpen(true);
  };

  return (
    <div className="gallery-window">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="slide">
            <LazyLoadImage
              src={image.highResUrl}
              placeholderSrc={image.lowResUrl}
              alt={`Slide ${index}`}
              onClick={(e) => openModal(image, e)} 
            />
            <div className="caption">{image.caption}</div>
          </div>
        ))}
      </Slider>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} center>
        {selectedImage && (
          <>
            <TransformWrapper>
              <TransformComponent>
                <img 
                  src={selectedImage.ultraHighResUrl} 
                  alt={selectedImage.caption} 
                  className="modal-image"
                />
              </TransformComponent>
            </TransformWrapper>
            <button 
              onClick={() => {
                const link = document.createElement('a');
                link.href = selectedImage.ultraHighResUrl;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              className="modal-button"
            >
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default GalleryWindow;
