import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/navbar.css';

const logoSvg = process.env.PUBLIC_URL + '/images/curved-cropped2.svg';

function Navbar() {
  const [activeLink, setActiveLink] = useState('/'); // Установите начальное состояние в зависимости от вашего маршрута

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container custom-container">

        <Link 
          className={`lab-name-and-logo ${activeLink === '/' ? 'active' : ''}`} 
          to="/" 
          onClick={() => handleLinkClick('/')}
        >
          <span className="lab-name">BORG</span>
          <img src={logoSvg} alt="BORG Logo" className="borg-logo" />
          <span className="navbar-brand">Rubalkhali</span>
        </Link>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link 
                className={`nav-link ${activeLink === '/about' ? 'active' : ''}`} 
                to="/about" 
                onClick={() => handleLinkClick('/about')}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`nav-link ${activeLink === '/gallery' ? 'active' : ''}`} 
                to="/gallery" 
                onClick={() => handleLinkClick('/gallery')}
              >
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`nav-link ${activeLink === '/contact' ? 'active' : ''}`} 
                to="/contact" 
                onClick={() => handleLinkClick('/contact')}
              >
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`nav-link ${activeLink === '/sparqlendpoint' ? 'active' : ''}`} 
                to="/sparqlendpoint" 
                onClick={() => handleLinkClick('/sparqlendpoint')}
              >
                SPARQL
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
