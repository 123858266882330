import { BASE_URL } from '../data/config.js'; 
const base_address = BASE_URL + 'gallery';
const path_names = ["compress_320", "compress_720", "compress_1080", "compress_4k", "original"];

const imageCountsFirstExp =
{
  "2": 3,
  "31": 4,
  "50": 2,
  "17": 2,
  "63": 3,
  "27": 4,
  "58": 2,
  "7": 2,
  "22": 3,
  "6": 3,
  "14": 3,
  "39": 2,
  "55": 2,
  "33": 2,
  "34": 2,
  "42": 2,
  "56": 1,
  "43": 2,
  "37": 2,
  "44": 2,
  "48": 5,
  "1": 3,
  "9": 3,
  "64": 2,
  "61": 2,
  "21": 2,
  "28": 2,
  "45": 2,
  "11": 2,
  "49": 2,
  "52": 2,
  "60": 2,
  "62": 2,
  "38": 3,
  "51": 2,
  "30": 2,
  "3": 3,
  "8": 2,
  "24": 2,
  "46": 2,
  "4": 2,
  "57": 2,
  "15": 2,
  "41": 5,
  "13": 1,
  "32": 3,
  "29": 2,
  "40": 2,
  "20": 5,
  "19": 5,
  "12": 2,
  "26": 2,
  "36": 2,
  "35": 2,
  "54": 3,
  "16": 2,
  "25": 2,
  "53": 2,
  "18": 2,
  "5": 3,
  "23": 2,
  "47": 4,
  "10": 2,
  "59": 4
}

const imageCountsSecondExp = {
  "45": 3,
  "37": 2,
  "34": 2,
  "38": 2,
  "35": 2,
  "hot spring": 5,
  "40": 3,
  "11": 2,
  "24": 2,
  "19": 2,
  "54": 2,
  "2": 3,
  "22": 1,
  "12": 2,
  "9": 1,
  "6": 1,
  "plant gradient": 4,
  "36": 2,
  "15": 1,
  "41": 2,
  "23": 2,
  "Camel1": 1,
  "26": 2,
  "33": 2,
  "17": 2,
  "30": 2,
  "5": 8,
  "43": 3,
  "55": 2,
  "57": 2,
  "20": 2,
  "water well": 1,
  "7": 2,
  "44": 2,
  "16": 2,
  "25": 2,
  "42": 2,
  "52": 2,
  "58": 2,
  "49": 2,
  "50": 2,
  "47": 2,
  "59": 2,
  "1": 2,
  "13": 2,
  "53": 2,
  "28": 1,
  "56": 2,
  "51": 3,
  "27": 2,
  "60": 2,
  "29": 2,
  "3": 2,
  "14": 3,
  "8": 2,
  "18": 1,
  "31": 2,
  "32": 2,
  "21": 1,
  "39": 2,
  "10": 2,
  "46": 2,
  "48": 3
}


const imageCountsMapFirstExp = {};

Object.keys(imageCountsFirstExp).forEach(key => {
  path_names.forEach(path_name => {
    const url = `${base_address}/${path_name}/${key}`;
    imageCountsMapFirstExp[url] = imageCountsFirstExp[key];
  });
});

const imageCountsMapSecondExp = {};

Object.keys(imageCountsSecondExp).forEach(key => {
  path_names.forEach(path_name => {
    const url = `${base_address}/${path_name}/${key}`;
    imageCountsMapSecondExp[url] = imageCountsSecondExp[key];
  });
});


export {imageCountsFirstExp, imageCountsSecondExp, imageCountsMapFirstExp, imageCountsMapSecondExp};