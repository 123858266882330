import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/HomePage';
import Contact from './components/ContactPage';
import About from './components/AboutPage';
import Gallery from './components/Gallery';
import ExpeditionSelector from './components/ExpeditionSelector';
import NotFoundPage from './components/NotFoundPage';
import SparqlQuery from './components/SparqlQuery';

function AppRouter() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="about" element={<About />} />
      <Route path="gallery" element={<ExpeditionSelector />} />
      <Route path="gallery/exp1" element={<Gallery expedition="exp1" />} />
      <Route path="gallery/exp2" element={<Gallery expedition="exp2" />} />
      <Route path="/sparqlendpoint" element={<SparqlQuery />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRouter;
