import React, { useState, useEffect, useRef } from 'react';
import QUERY_OPTIONS from '../data/queryOptions';
import { BASE_URL } from '../data/config.js'; 
import Yasgui from "@triply/yasgui";
import "@triply/yasgui/build/yasgui.min.css";
import '../styles/SparqlQuery.css';

// Dropdown component for selecting query templates
function QueryTemplates({ onSelect }) {
  return (
    <select className="form-control" onChange={(e) => onSelect(e.target.value)}>
      <option value="">Select a query...</option>
      {QUERY_OPTIONS.map((template) => (
        <option key={template.name} value={template.value}>
          {template.name}
        </option>
      ))}
    </select>
  );
}

function SparqlQuery({ onResultsChange }) {
  const yasguiRef = useRef(null);
  const [selectedQuery, setSelectedQuery] = useState('');

  useEffect(() => {
    const yasgui = yasguiRef.current ? new Yasgui(yasguiRef.current, {
      requestConfig: {
        endpoint: BASE_URL + "sparql",
        headers: {},
        method: "POST"
      }
    }) : null;

    if (yasgui) {
      yasgui.on("queryResponse", (response) => {
        if (response && response.results) {
          onResultsChange(response.results);
        }
      });

      if (selectedQuery) {
        yasgui.getTab().yasqe.setValue(selectedQuery);
        yasgui.getTab().yasqe.query();
      }
    }

    return () => {
      if (yasgui) {
        yasgui.destroy();
      }
    };
  }, [selectedQuery, onResultsChange]);

  const handleSelectTemplate = (query) => {
    setSelectedQuery(query);
  };

  return (
    <div className="container mt-5">
      <h1>SPARQL Query</h1>
      <QueryTemplates onSelect={handleSelectTemplate} />
      <div ref={yasguiRef} id="yasgui" style={{ height: '500px', width: '100%' }}></div>
    </div>
  );
}

export default SparqlQuery;

