import { BASE_URL } from './config';

const baseUrl = BASE_URL.replace('https://', 'http://');

const QUERY_OPTIONS = [
  {
    name: 'List All Items',
    value: `PREFIX d: <${baseUrl}eqp/EGP-D1/> 
PREFIX egp: <${baseUrl}eqp/vocab/> 
PREFIX eqp: <${baseUrl}eqp/> 
PREFIX geo: <http://www.opengis.net/ont/geosparql#> 
PREFIX obo: <http://purl.obolibrary.org/obo/> 
PREFIX qudt: <http://qudt.org/vocab/unit/> 
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> 
PREFIX wgs84_pos: <http://www.w3.org/2003/01/geo/wgs84_pos#> 
PREFIX schema: <http://schema.org/> 
PREFIX foaf: <http://xmlns.com/foaf/0.1/> 
PREFIX sio: <http://semanticscience.org/resource/> 
 
SELECT  
    ?label  
    ?image  
    ?comment  
    ?identifier  
    ?lat  
    ?long  
    ?atmospheric_pressure  
    ?temperature  
    ?humidity  
    ?salinity  
    ?isLocatedIn  
    ?isAdjacentTo 
WHERE { 
    ?site a schema:Location ; 
          rdfs:label ?label ; 
          schema:identifier ?identifier ; 
          egp:atmospheric_pressure ?atmosphericPressure ; 
          egp:humidity ?humidityRaw ; 
          egp:salinity ?salinityRaw ; 
          egp:temperature ?temperatureRaw ; 
          rdfs:comment ?comment ; 
          foaf:depiction ?image ; 
          wgs84_pos:lat ?latitude ; 
          wgs84_pos:long ?longitude ; 
          sio:isLocatedIn ?isLocatedIn ; 
          sio:isAdjacentTo ?isAdjacentTo . 

    BIND(STR(?atmosphericPressure) AS ?atmospheric_pressure)
    BIND(STR(?temperatureRaw) AS ?temperature)
    BIND(STR(?humidityRaw) AS ?humidity)
    BIND(STR(?salinityRaw) AS ?salinity)
    BIND(STR(?latitude) AS ?lat)
    BIND(STR(?longitude) AS ?long)
}`
  },
  {
    name: 'List 10 Items',
    value: `PREFIX d: <${baseUrl}eqp/EGP-D1/> 
PREFIX egp: <${baseUrl}eqp/vocab/> 
PREFIX eqp: <${baseUrl}eqp/> 
PREFIX geo: <http://www.opengis.net/ont/geosparql#> 
PREFIX obo: <http://purl.obolibrary.org/obo/> 
PREFIX qudt: <http://qudt.org/vocab/unit/> 
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> 
PREFIX wgs84_pos: <http://www.w3.org/2003/01/geo/wgs84_pos#> 
PREFIX schema: <http://schema.org/> 
PREFIX foaf: <http://xmlns.com/foaf/0.1/> 
PREFIX sio: <http://semanticscience.org/resource/> 
 
SELECT  
    ?label  
    ?image  
    ?comment  
    ?identifier  
    ?lat  
    ?long  
    ?atmospheric_pressure  
    ?temperature  
    ?humidity  
    ?salinity  
    ?isLocatedIn  
    ?isAdjacentTo 
WHERE { 
    ?site a schema:Location ; 
          rdfs:label ?label ; 
          schema:identifier ?identifier ; 
          egp:atmospheric_pressure ?atmosphericPressure ; 
          egp:humidity ?humidityRaw ; 
          egp:salinity ?salinityRaw ; 
          egp:temperature ?temperatureRaw ; 
          rdfs:comment ?comment ; 
          foaf:depiction ?image ; 
          wgs84_pos:lat ?latitude ; 
          wgs84_pos:long ?longitude ; 
          sio:isLocatedIn ?isLocatedIn ; 
          sio:isAdjacentTo ?isAdjacentTo . 

    BIND(STR(?atmosphericPressure) AS ?atmospheric_pressure)
    BIND(STR(?temperatureRaw) AS ?temperature)
    BIND(STR(?humidityRaw) AS ?humidity)
    BIND(STR(?salinityRaw) AS ?salinity)
    BIND(STR(?latitude) AS ?lat)
    BIND(STR(?longitude) AS ?long)
}
LIMIT 10`
  },
  {
    name: "all points within a distance of 20 km from site 2",
    value: `PREFIX geo: <http://www.opengis.net/ont/geosparql#> 
PREFIX geof: <http://www.opengis.net/def/function/geosparql/> 
PREFIX units: <http://www.opengis.net/def/uom/OGC/1.0/> 
PREFIX d: <${baseUrl}eqp/EGP-D1/> 
PREFIX eqp: <${baseUrl}eqp/> 
PREFIX sio: <http://semanticscience.org/resource/> 
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> 
PREFIX schema: <http://schema.org/> 
PREFIX foaf: <http://xmlns.com/foaf/0.1/> 
PREFIX wgs84_pos: <http://www.w3.org/2003/01/geo/wgs84_pos#> 
    
SELECT  
    ?label  
    ?image  
    ?comment  
    ?identifier  
    ?lat  
    ?long  
WHERE { 
    d:S2 geo:hasGeometry ?geo2 . 
    ?geo2 geo:asWKT ?wkt2 . 
    ?site a schema:Location ; 
          rdfs:label ?label ; 
          schema:identifier ?identifier ; 
          rdfs:comment ?comment ; 
          foaf:depiction ?image ; 
          wgs84_pos:lat ?latitude ; 
          wgs84_pos:long ?longitude ; 
          geo:hasGeometry ?geo1 . 
    ?geo1 geo:asWKT ?wkt1 .
        
    BIND (geof:distance(?wkt1, ?wkt2, units:meter) AS ?distance) 
    FILTER (?distance <= 20000)
    BIND(STR(?latitude) AS ?lat)
    BIND(STR(?longitude) AS ?long)
}
    
`
  },
  {
    name: 'Sample Query for a Site',
    value: `PREFIX schema1: <http://schema.org/>
PREFIX sio: <http://semanticscience.org/resource/>
PREFIX geo: <http://www.opengis.net/ont/geosparql#>
PREFIX egp: <${baseUrl}eqp/vocab/>
PREFIX qudt: <http://qudt.org/vocab/unit/>
PREFIX obo: <http://purl.obolibrary.org/obo/>
PREFIX d: <${baseUrl}eqp/EGP-D1/> 

SELECT ?sample ?label ?identifier ?derivedFrom ?collectedAt ?depth ?replicate ?storageTemperature
WHERE {
  ?sample a schema1:Sample ;
          rdfs:label ?label ;
          schema1:identifier ?identifier ;
          egp:collected-at ?collectedAt .
  //FILTER will be added dynamically
  OPTIONAL { ?sample sio:isDerivedFrom ?derivedFrom . }
  OPTIONAL { ?sample egp:depth ?depth . }
  OPTIONAL { ?sample egp:replicate ?replicate . }
  OPTIONAL { ?sample egp:storageTemperature ?storageTemperature . }
}`
  },
  {
  name: 'DNA Sample Information',
  value: `PREFIX schema1: <http://schema.org/>
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> 
PREFIX sio: <http://semanticscience.org/resource/>
PREFIX egp: <${baseUrl}eqp/vocab/>
PREFIX d: <${baseUrl}eqp/EGP-D1/>
PREFIX so: <http://purl.obolibrary.org/obo/so#>
PREFIX dct: <http://purl.org/dc/terms/>

SELECT ?sample_id ?dnaLabel ?dnaIdentifier ?extractionMethod ?libraryLabel ?forwardPrimer ?instrument ?libraryPrepMethod ?reversePrimer ?libraryIdentifier
WHERE {
  VALUES ?sample_id { d:sample_id } 

  # DNA
  OPTIONAL {
    ?dnaId a so:0000352 ;
           sio:isDerivedFrom ?sample_id ;
           rdfs:label ?dnaLabel ;
           schema1:identifier ?dnaIdentifier .
    OPTIONAL { ?dnaId egp:extractionMethod ?extractionMethod . }

    OPTIONAL {
      # Library связанные с DNA
      ?libraryId dct:isPartOf ?dnaId ;
                 rdfs:label ?libraryLabel ;
                 schema1:identifier ?libraryIdentifier .
      OPTIONAL { ?libraryId egp:forwardPrimerSequence ?forwardPrimer . }
      OPTIONAL { ?libraryId egp:instrument ?instrument . }
      OPTIONAL { ?libraryId egp:libraryPreparationMethod ?libraryPrepMethod . }
      OPTIONAL { ?libraryId egp:reversePrimerSequence ?reversePrimer . }
    }
  }
}`
  },
  {
  name: 'OTU Sample Information',
  value: `PREFIX schema1: <http://schema.org/>
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#> 
PREFIX sio: <http://semanticscience.org/resource/>
PREFIX egp: <${baseUrl}eqp/vocab/>
PREFIX d: <${baseUrl}eqp/EGP-D1/>
PREFIX so: <http://purl.obolibrary.org/obo/so#>
PREFIX dct: <http://purl.org/dc/terms/>
  
SELECT ?otuId ?abundance ?accessionNumber ?assemblyIdentifier ?otuName
WHERE {
  ?sample_id schema1:identifier "1Sr2" .
  ?dnaId sio:isDerivedFrom ?sample_id ;
          schema1:identifier ?dnaIdentifier .
  ?otuId a egp:OTU ;
          dct:isPartOf ?someDna ;
          egp:abundance ?abundance ;
          egp:accessionNumber ?accessionNumber ;
          egp:assemblyIdentifier ?assemblyIdentifier ;
          egp:otu_name ?otuName .
  FILTER (STR(?someDna) = CONCAT("${baseUrl}eqp/EGP-D1/R1L1", ?dnaIdentifier))
  }`
  },
  {
    name: "Soil Sample Information",
    value: `PREFIX schema1: <http://schema.org/>
PREFIX sio: <http://semanticscience.org/resource/>
PREFIX egp: <${baseUrl}eqp/vocab/>
PREFIX d: <${baseUrl}eqp/EGP-D1/>
PREFIX so: <http://purl.obolibrary.org/obo/so#>
PREFIX dct: <http://purl.org/dc/terms/>
    
SELECT ?sample_id ?soilLabel ?soilIdentifier ?soilPrepMethod ?xrfLabel ?xrfDiameter ?xrfInstrument ?xrfInstrumentMethod ?xrfMaterial ?xrfMethod ?xrfMode (GROUP_CONCAT(DISTINCT CONCAT(?chemComp, " (name: ", ?chemLabel, ", Atomic Number: ", STR(?atomicNumber), ", Concentration: ", STR(?concentration), ")"); separator = "; ") AS ?chemicalCompositions)
WHERE {
  VALUES ?sample_id { d:1Sr2 }
    
    # Ground Soil
  OPTIONAL {
    ?soilId a egp:GroundSoil ;
            sio:isDerivedFrom ?sample_id ;
            rdfs:label ?soilLabel ;
            schema1:identifier ?soilIdentifier .
    OPTIONAL { ?soilId egp:samplePreparationMethod ?soilPrepMethod . }
  }
    
    # XRF Analysis
  OPTIONAL {
    ?xrfId a egp:XRFAnalysis ;
            dct:isPartOf ?soilId ;
            rdfs:label ?xrfLabel .
    OPTIONAL { ?xrfId egp:diameter ?xrfDiameter . }
    OPTIONAL { ?xrfId egp:instrument ?xrfInstrument . }
    OPTIONAL { ?xrfId egp:instrumentMethod ?xrfInstrumentMethod . }
    OPTIONAL { ?xrfId egp:material ?xrfMaterial . }
    OPTIONAL { ?xrfId egp:method ?xrfMethod . }
    OPTIONAL { ?xrfId egp:mode ?xrfMode . }
    OPTIONAL {
      ?xrfId egp:chemicalComposition ?chemCompId .
      ?chemCompId rdfs:label ?chemComp .
      OPTIONAL { ?chemCompId egp:atomicNumber ?atomicNumber . }
      OPTIONAL { ?chemCompId egp:concentration ?concentration . }
      OPTIONAL { ?chemCompId rdfs:label ?chemLabel . }
    }
  }
}
GROUP BY ?sample_id ?soilLabel ?soilIdentifier ?soilPrepMethod ?xrfLabel ?xrfDiameter ?xrfInstrument ?xrfInstrumentMethod ?xrfMaterial ?xrfMethod ?xrfMode`
  },
  {
    name: "Specific Sample Details Query",
    value: `PREFIX schema1: <http://schema.org/>
PREFIX sio: <http://semanticscience.org/resource/>
PREFIX geo: <http://www.opengis.net/ont/geosparql#>
PREFIX egp: <${baseUrl}eqp/vocab/>
PREFIX qudt: <http://qudt.org/vocab/unit/>
PREFIX obo: <http://purl.obolibrary.org/obo/>
PREFIX d: <${baseUrl}eqp/EGP-D1/> 
    
SELECT ?sample ?label ?identifier ?derivedFrom ?collectedAt ?depth ?replicate ?storageTemperature
WHERE {
  ?sample a schema1:Sample ;
          schema1:identifier ?identifier ;
          rdfs:label ?label ;
          egp:collected-at ?collectedAt .
  VALUES ?identifier { "your_sample_id" }  # Replace your_sample_id with the actual sample id
  OPTIONAL { ?sample sio:isDerivedFrom ?derivedFrom . }
  OPTIONAL { ?sample egp:depth ?depth . }
  OPTIONAL { ?sample egp:replicate ?replicate . }
  OPTIONAL { ?sample egp:storageTemperature ?storageTemperature . }
}`
  }
];

export default QUERY_OPTIONS;