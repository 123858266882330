import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    isVisible ? (
    <button 
      onClick={scrollToTop} 
      style={{ 
        position: 'fixed', 
        bottom: '20px', 
        right: '20px',
        backgroundColor: 'rgba(0, 45, 128, 0.5)', // Серый цвет с полупрозрачностью
        color: 'white',
        border: 'none',
        borderRadius: '50%', // Круглая форма
        width: '50px',
        height: '50px',
        textAlign: 'center',
        lineHeight: '50px', // Центрирование иконки или текста
        boxShadow: '0 4px 8px rgba(0,0,0,0.3)', // Мягкая тень для глубины
        cursor: 'pointer',
        fontSize: '24px', // Размер иконки или текста
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.3s', // Плавное изменение прозрачности при наведении
      }}
      onMouseOver={({ target }) => target.style.backgroundColor = 'rgba(0, 45, 128, 0.8)'} // Более непрозрачный при наведении
      onMouseOut={({ target }) => target.style.backgroundColor = 'rgba(0, 45, 128, 0.5)'} // Исходная прозрачность при убирании курсора
      >
        &#11014;
    </button>
    ) : null
  );  
};

export default ScrollToTopButton;
