import React from 'react';
import GalleryWindow from './GalleryWindow';
import '../styles/galleryWindow.css'; 
import { imageCountsFirstExp, imageCountsSecondExp } from "../data/imageCountsMap"; // Убедитесь, что у вас есть данные для второй экспедиции
import { BASE_URL } from '../data/config.js'; 

function Gallery({ expedition }) {
  // Выбор набора данных в зависимости от выбранной экспедиции
  const imageCounts = expedition === 'exp1' ? imageCountsFirstExp : imageCountsSecondExp;

  // Динамическое создание URL в зависимости от экспедиции
  const baseLowResUrl = `${BASE_URL}/photos/photo_${expedition}/compress_320/`;
  const baseHighResUrl = `${BASE_URL}/photos/photo_${expedition}/compress_1080/`;
  const baseUltraHighResUrl = `${BASE_URL}/photos/photo_${expedition}/compress_4k/`;

  let imageData = [];
  for (const [key, count] of Object.entries(imageCounts)) {
    let data = []
    for (let i = 1; i <= count; i++) {
      data.push({
        lowResUrl: `${baseLowResUrl}${key}/${i}.jpg`,
        highResUrl: `${baseHighResUrl}${key}/${i}.jpg`,
        ultraHighResUrl: `${baseUltraHighResUrl}${key}/${i}.jpg`, 
        caption: `SITE ${key}-${i}` 
      });
    }
    imageData.push(data);
  }

  return (
    <div className="gallery">
      {imageData.map((images, index) => (
        <GalleryWindow key={index} images={images} />
      ))}
    </div>
  );
}

export default Gallery;
