import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Убедитесь, что Bootstrap CSS импортирован

function NotFoundPage() {
  return (
    <div className="container text-center mt-5">
      <h1 className="display-1 fw-bold">404</h1>
      <p className="fs-3"> <span className="text-danger">Oops!</span> Page not found.</p>
      <p className="lead">
        The page you’re looking for doesn’t exist.
      </p>
      <a href="/" className="btn btn-primary">Go Home</a>
    </div>
  );
}

export default NotFoundPage;
