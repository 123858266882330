import React from 'react';
import '../styles/AboutPage.css';

const backgroundUrl = process.env.PUBLIC_URL + '/images/back.png';
const video1 = process.env.PUBLIC_URL + '/videos/arabic_name.mp4';
const video2 = process.env.PUBLIC_URL + '/videos/hotspring.mp4'; 

function AboutPage() {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundUrl})`,
    backgroundSize: 'cover',
    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.5)'
  };

  return (
    <div className="about-page" style={backgroundStyle}>
      <div className="content">
        <h1>About Our Project</h1>
        <p>This page provides information about our project.</p>

        {/* First Video */}
        <video className="responsive-video" controls>
          <source src={`${video1}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Title for the Second Video */}
        <h2 className="video-title">Hot Spring</h2>

        {/* Second Video */}
        <video className="responsive-video" controls>
          <source src={`${video2}`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default AboutPage;
