import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {imageCountsFirstExp} from "../data/imageCountsMap"
import Modal from 'react-responsive-modal';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import '../styles/map.css';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: process.env.PUBLIC_URL + '/images/icons/marker-icon-2x.png',
  iconUrl: process.env.PUBLIC_URL + '/images/icons/marker-icon.png',
  shadowUrl: process.env.PUBLIC_URL + '/images/icons/marker-shadow.png'
});

function Map({ queryResults, isError, onMarkerClick, sampleResults, onSampleClick}) {
  const [markers, setMarkers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [hasGeoData, setHasGeoData] = useState(true);

  const openModal = (imageData) => {
    setSelectedImage(imageData);
    setModalOpen(true);
  };

  const handleBothClicks = (sample) => {
    onSampleClick(sample);
  };  

  const zoomLevel = window.innerWidth < 768 ? 5 : 7;

  useEffect(() => {
    if (queryResults && queryResults.length > 0) {
      const validGeoData = queryResults.some(result => result.lat && result.long && !isNaN(result.lat) && !isNaN(result.long));
      setHasGeoData(validGeoData);

      if (validGeoData) {
        const newMarkers = queryResults.map(queryResult => {
          const urlParts = queryResult.image.split('/');
          const siteNumber = urlParts[urlParts.length - 1]; 
          const basePath = urlParts.slice(0, urlParts.length - 2).join('/') + '/';
          const count = imageCountsFirstExp[siteNumber] || 0;
          const imageURLs = Array.from({ length: count }, (_, i) => ({
            lowResUrl: `${basePath}compress_320/${siteNumber}/${i + 1}.jpg`,
            highResUrl: `${basePath}compress_1024/${siteNumber}/${i + 1}.jpg`,
            ultraHighResUrl: `${basePath}original/${siteNumber}/${i + 1}.jpg`
          }));

          return {
            ...queryResult,
            imageURLs
          };
        });
      setMarkers(newMarkers);
      } else {
        setMarkers([]); // Очищаем маркеры, так как геоданные невалидны
      }
    }
  }, [queryResults]);
  
  const centerPosition = [21, 49.891405];

  return (
    <MapContainer center={centerPosition} zoom={zoomLevel} attributionControl={false}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      
      {hasGeoData && markers.map((marker, index) => (
        <Marker 
          key={index} 
          position={[parseFloat(marker.lat), parseFloat(marker.long)]}
          eventHandlers={{
            click: () => onMarkerClick(marker.identifier.split('-')[1]),
          }}
        >
          <Popup
            autoPanPaddingTopLeft={L.point(0, 80)}>
            <div className="popup-container">
              <div className="popup-content">
                {marker.imageURLs.length > 1 ? (
                  <Slider>
                    {marker.imageURLs.map((imageData, index) => (
                      <div key={index} onClick={() => openModal(imageData)}>
                        <img
                          className="popup-image"
                          src={imageData.lowResUrl}
                          alt={`Slide ${index + 1}`}
                          width="200"
                        />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div onClick={() => openModal(marker.imageURLs[0])}>
                    <img
                     className="popup-image"
                      src={marker.imageURLs[0].lowResUrl}
                      alt="Slide 1"
                      width="200"
                    />
                  </div>
                )}
                <h3>{marker.label}</h3>
                <p><strong>Identifier:</strong> {marker.identifier}</p>
                <p><strong>Longitude:</strong> {marker.long}</p>
                <p><strong>Latitude:</strong> {marker.lat}</p>
                <p><strong>Atmospheric Pressure:</strong> {marker.atmospheric_pressure}</p>
                <p><strong>Temperature:</strong> {marker.temperature}</p>
                <p><strong>Humidity:</strong> {marker.humidity}</p>
                <p><strong>Salinity:</strong> {marker.salinity}</p>
                <p><strong>Comment:</strong> {marker.comment}</p>
                <p><strong>Located In:</strong> <a href={marker.isLocatedIn} target="_blank" rel="noopener noreferrer">{marker.isLocatedIn}</a></p>
                <p><strong>Adjacent To:</strong> <a href={marker.isAdjacentTo} target="_blank" rel="noopener noreferrer">{marker.isAdjacentTo}</a></p>
              </div>
              <div className="popup-additional-info">
                <h3 className="samples-header">Samples</h3>
                <ul>
                  {sampleResults && sampleResults.map((sample, index) => (
                    <li key={index} onClick={() => handleBothClicks(sample)}>
                       <span className="sample-link">{sample.identifier}</span>
                    </li>
                    ))}
                </ul>
              </div>
            </div>
          </Popup>
        </Marker>
      ))}
      {!hasGeoData && (
        <div className="no-geo-data-message">
          No geo-data, markers not showing
        </div>
      )}
      {isError && (
        <>
          <div className="map-overlay"></div>
          <div className="map-error-message">
            An error occurred while loading data.
          </div>
        </>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)} center>
        {selectedImage && (
          <>
            <TransformWrapper>
              <TransformComponent>
                <img 
                  src={selectedImage.ultraHighResUrl} 
                  className="modal-image"
                  alt=""
               />
              </TransformComponent>
            </TransformWrapper>
          </>
        )}
      </Modal>
    </MapContainer>
  );
}

export default Map;