const ChemicalCompositionsTable = ({ chemicalCompositions }) => {
    const compositions = chemicalCompositions.split('; ').map(comp => {
      const parts = comp.split(', ');
      return {
        name: parts[0].split(': ')[1],
        atomicNumber: parts[1].split(': ')[1],
        concentration: parts[2].split(': ')[1].replace(')', ''),
      };
    });
  
    return (
      <div>
        <table className="table table-sm">
          <thead>
            <tr>
              <th style={{ width: '38.8%' }}>Name</th>
              <th style={{ width: '29%' }}>Atomic Number</th>
              <th style={{ width: '29%' }}>Concentration</th>
              <th style={{ width: '3.2%' }}></th>
            </tr>
          </thead>
        </table>
        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
          <table className="table table-sm">
            <tbody>
              {compositions.map((comp, index) => (
                <tr key={index}>
                  <td style={{ width: '40%' }}>{comp.name}</td>
                  <td style={{ width: '30%' }}>{comp.atomicNumber}</td>
                  <td style={{ width: '30%' }}>{comp.concentration}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  
  

export default ChemicalCompositionsTable;