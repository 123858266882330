// AbundanceChart.js
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const AbundanceChart = ({ data }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (data && d3Container.current) {
      // Clear the container first
      d3.select(d3Container.current).selectAll('*').remove();

      // Calculate total abundance to find percentages, ensuring abundance is treated as a number
      const totalAbundance = data.reduce((acc, { abundance }) => acc + Number(abundance), 0);


      // Map data to percentages, converting abundance to number for calculation
      const dataWithPercentage = data.map(d => ({
        ...d,
        percentage: (Number(d.abundance) / totalAbundance) * 100
      }));

      // Sort data by abundance in descending order, converting abundance to number for sorting
      dataWithPercentage.sort((a, b) => d3.descending(Number(a.abundance), Number(b.abundance)));

      // Set the dimensions and margins of the graph
      const margin = { top: 20, right: 20, bottom: 30, left: 40 },
            width = 300 - margin.left - margin.right,
            height = 500 - margin.top - margin.bottom; // Increased height

      // Append the SVG object to the container
      const svg = d3.select(d3Container.current)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // Create a scale for y-axis
      const y = d3.scaleLinear()
        .domain([0, 100]) // as we're dealing with percentages
        .range([height, 0]);

      // Create one single bar
      const bar = svg.append('g');

      const category10Colors = d3.schemeCategory10;
      const customColors = [
        '#393b79', '#637939','#1e51a3', '#1b1e71', '#bd91d1','#12e1a3',
        '#8ca252', '#b5cf6b', '#cedb9c', '#8c6d31', '#bd9e39'
      ];

      const combinedColors = category10Colors.concat(customColors);
      
      const color = d3.scaleOrdinal(combinedColors);
      // Append each segment to the bar, with corrected cumulative percentage calculation
      let yOffset = 0; 
      
      dataWithPercentage.forEach(d => {
        const segmentHeight = (height * Number(d.percentage)) / 100;
        bar.append('rect')
          .attr('x', 0)
          .attr('y', height - yOffset - segmentHeight) 
          .attr('width', width)
          .attr('height', segmentHeight)
          .attr('fill', color(d.otuName)); 

        
        const textY = height - yOffset - (segmentHeight / 2);
      
      
        if (d.percentage >= 2) {
          bar.append('text')
            .attr('x', width / 2)
            .attr('y', textY)
            .attr('dy', ".35em") 
            .attr('text-anchor', 'middle') 
            .text(`${d.otuName}: ${d.abundance}`)
            .attr('fill', 'white')
            .style('font-size', '10px'); 
        }
      
        yOffset += segmentHeight; 
      });
      

      // Add the Y Axis
      svg.append('g').call(d3.axisLeft(y));
      svg.append("text")
        .attr("transform", "rotate(-90)") 
        .attr("y", 0 - (1.1*margin.left))
        .attr("x",0 - (height / 2)) 
        .attr("dy", "1em") 
        .style("text-anchor", "middle") 
        .text("Abundance "); 
    }
  }, [data]); // Redraw chart if data changes

  return (
    <div className="chart-container abundance-chart text-center" ref={d3Container}></div>
  );  
};

export default AbundanceChart;
