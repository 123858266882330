import React from 'react';
import '../styles/sampleDetails.css';
import AbundanceChart from './AbundanceChart';
import ChemicalCompositionsTable from './ChemicalCompositionsTable';
import { transformOTUData } from '../utils/dataforChartUtils';

function SampleDetails({ sample, sampleDetails }) {
  if (!sample && !sampleDetails) return null;
  let dnaResults = sampleDetails?.dna?.[0] || null;
  let otuResults = sampleDetails?.otu || null;
  let soilResults = sampleDetails?.soil?.[0] || null;

  const chartData = otuResults ? transformOTUData(otuResults) : null;

  console.log('Soil Results:', chartData);

  if (!sample && !dnaResults) return <p>No Data Available</p>;

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="row">
                {/* Секция для Sample */}
                <div className="col-md-6 card-details">
                  <h3 className="card-title text-center">
                    Sample {sample ? sample.identifier : 'Unknown'}
                  </h3>
                  {sample ? (
                    <>
                      <p className="card-text"><strong>Label: </strong>{sample.label}</p>
                      <p className="card-text"><strong>Identifier: </strong>{sample.identifier}</p>
                      <p className="card-text"><strong>Derived From: </strong><a href={sample.derivedFrom} target="_blank" rel="noopener noreferrer">{sample.derivedFrom}</a></p>
                      <p className="card-text"><strong>Depth: </strong>{sample.depth}</p>
                      <p className="card-text"><strong>Storage Temperature: </strong>{sample.storageTemperature}</p>
                      <p className="card-text"><strong>Replicate: </strong>{sample.replicate}</p>
                      <p className="card-text"><strong>Collected At: </strong><a href={sample.collectedAt} target="_blank" rel="noopener noreferrer">{sample.collectedAt}</a></p>
                    </>
                  ) : (
                    <p>No Sample Data Available</p>
                  )}
                </div>

                {/* Секция для DNA */}
                <div className="col-md-6 card-details">
                  <h3 className="card-title text-center">DNA</h3>
                  {dnaResults ? (
                    <>
                      <p className="card-text"><strong>Label: </strong>{dnaResults.dnaLabel}</p>
                      <p className="card-text"><strong>Extraction Method: </strong>{dnaResults.extractionMethod}</p>
                      <h4 className="h4-title">Amplicon Library</h4>
                      <p className="card-text"><strong>Label: </strong>{dnaResults.libraryLabel}</p>
                      <p className="card-text"><strong>Forward Primer: </strong>{dnaResults.forwardPrimer}</p>
                      <p className="card-text"><strong>Reverse Primer: </strong>{dnaResults.reversePrimer}</p>
                      <p className="card-text"><strong>Instrument: </strong>{dnaResults.instrument}</p>
                      <p className="card-text"><strong>Preparation Method: </strong><a href={dnaResults.libraryPrepMethod} target="_blank" rel="noopener noreferrer">{dnaResults.libraryPrepMethod}</a></p>
                    </>
                  ) : (
                    <p>No DNA Data Available</p>
                  )}
                </div>
              </div>
              {/* Дополнительный ряд для графика Abundance Chart */}
              <div className="row">
                <div className="col-md-6 card-details">
                  <h3 className="card-title text-center">OTU Chart</h3>
                  {otuResults ? (
                    <AbundanceChart data={otuResults} />
                  ) : (
                    <p>No OTU Data Available</p>
                  )}
                </div>
                <div className="col-md-6 card-details">
                  <h3 className="card-title text-center">Soil</h3>
                  {soilResults ? (
                    <>
                      <p className="card-text"><strong>Label: </strong>{soilResults.soilLabel}</p>
                      <p className="card-text"><strong>Preparation Method: </strong><a href={soilResults.soilPrepMethod} target="_blank" rel="noopener noreferrer">{soilResults.soilPrepMethod}</a></p>
                      <h4 className="h4-title">XRF</h4>
                      <p className="card-text"><strong>Label: </strong>{soilResults.xrfLabel}</p>
                      <p className="card-text"><strong>Instrument: </strong>{soilResults.xrfInstrument}</p>
                      <p className="card-text"><strong>Method: </strong>{soilResults.xrfMethod}</p>
                      <p className="card-text"><strong>Material: </strong>{soilResults.xrfMaterial}</p>
                      <p className="card-text"><strong>Mode: </strong>{soilResults.xrfMode}</p>
                      <p className="card-text"><strong>Diameter: </strong>{soilResults.xrfDiameter}</p>
                      <p className="card-text"><strong>Instrument Method: </strong>{soilResults.xrfInstrumentMethod}</p>
                      <h4 className="h4-title">Chemical Compositions</h4>
                      <ChemicalCompositionsTable chemicalCompositions={soilResults.chemicalCompositions} />
                    </>
                  ) : (
                    <p>No Soil Data Available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SampleDetails;
