// dataUtils.js
export const transformOTUData = (otuResults) => {
    // Группировка по sampleId
    const groupedBySampleId = otuResults.reduce((acc, { sampleId, otuName, abundance }) => {
      if (!acc[sampleId]) {
        acc[sampleId] = {};
      }
      if (!acc[sampleId][otuName]) {
        acc[sampleId][otuName] = 0;
      }
      acc[sampleId][otuName] += Number(abundance);
      return acc;
    }, {});
  
    // Преобразование группированных данных в массив объектов для каждого sampleId
    const transformedData = Object.keys(groupedBySampleId).map(sampleId => ({
      sampleId,
      ...groupedBySampleId[sampleId]
    }));
  
    return transformedData;
  };
  