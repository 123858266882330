import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar';
import ScrollToTopButton from './components/scroll';
import AppRouter from './Router';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <AppRouter />
        <ScrollToTopButton /> 
        <div className="container mt-0">
        </div>
      </div>
    </Router>
  );
}

export default App;
