import { useState, useCallback } from 'react';
import axios from 'axios';
import { BASE_URL } from '../data/config'; // Убедитесь, что BASE_URL правильно импортирован
import QUERY_OPTIONS from '../data/queryOptions';

export function useSparqlQuery() {
  const [results, setResults] = useState([]);
  const [isError, setIsError] = useState(false);
  const [sampleResults, setSampleResults] = useState(null);
  const [sampleDetails, setSampleDetails] = useState(null);
  const [selectedSample, setSelectedSample] = useState(null); 

  const processQueryResults = useCallback((bindings) => {
    return bindings.map(binding => {
      return Object.keys(binding).reduce((acc, key) => {
        acc[key] = binding[key].value;
        return acc;
      }, {});
    });
  }, []);

  const handleResults = useCallback((rawData) => {
    if (!rawData.results || !rawData.results.bindings) {
      setIsError(true);
      setResults([]);
      return;
    }
    const processedResults = processQueryResults(rawData.results.bindings);
    setResults(processedResults);
  }, [processQueryResults]);

  const fetchAndSetSampleById = useCallback(async (sampleId) => {
    try {
      const sampleQueryTemplate = QUERY_OPTIONS.find(option => option.name === 'Specific Sample Details Query').value;
      const sampleQuery = sampleQueryTemplate.replace('your_sample_id', sampleId);
      const encodedQuery = encodeURIComponent(sampleQuery);
      const response = await axios.get(`${BASE_URL}sparql?query=${encodedQuery}`);

      if (response.data && response.data.results && response.data.results.bindings.length > 0) {
        const sample = processQueryResults(response.data.results.bindings)[0]; // Assuming only one result
        setSelectedSample(sample);
        return sample;
      } else {
        throw new Error('No sample found with the specified ID');
      }
    } catch (error) {
      console.error('Error fetching sample by ID:', error.message);
      setIsError(true);
      return null;
    }
  }, [processQueryResults]);

  const fetchAndProcessSampleQuery = useCallback(async (siteId) => {
    try {
      const sampleQueryTemplate = QUERY_OPTIONS.find(option => option.name === 'Sample Query for a Site').value;
      const query = sampleQueryTemplate.replace('//FILTER will be added dynamically', `FILTER(?collectedAt = d:${siteId})`);
      const encodedQuery = encodeURIComponent(query);
      const response = await axios.get(`${BASE_URL}sparql?query=${encodedQuery}`);

      if (response.data && response.data.results && response.data.results.bindings) {
        return processQueryResults(response.data.results.bindings);
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      throw error;
    }
  }, [processQueryResults]);

  const fetchDNAData = useCallback(async (sampleId) => {
    try {
      const dnaQueryTemplate = QUERY_OPTIONS.find(option => option.name === 'DNA Sample Information').value;
      const dnaQuery = dnaQueryTemplate.replace('d:sample_id', `d:${sampleId}`);
      const dnaEncodedQuery = encodeURIComponent(dnaQuery);
      const dnaResponse = await axios.get(`${BASE_URL}sparql?query=${dnaEncodedQuery}`);

      if (dnaResponse.data && dnaResponse.data.results && dnaResponse.data.results.bindings) {
        return processQueryResults(dnaResponse.data.results.bindings);
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      throw error;
    }
  }, [processQueryResults]);

  const fetchSoilData = useCallback(async (sampleId) => {
    try {
      const soilQueryTemplate = QUERY_OPTIONS.find(option => option.name === 'Soil Sample Information').value;
      const soilQuery = soilQueryTemplate.replace('1Sr2', `${sampleId}`);
      const soilEncodedQuery = encodeURIComponent(soilQuery);
      const soilResponse = await axios.get(`${BASE_URL}sparql?query=${soilEncodedQuery}`);

      if (soilResponse.data && soilResponse.data.results && soilResponse.data.results.bindings) {
        return processQueryResults(soilResponse.data.results.bindings);
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      throw error;
    }
  }, [processQueryResults]);

  const fetchOTUData = useCallback(async (sampleId) => {
    try {
      const otuQueryTemplate = QUERY_OPTIONS.find(option => option.name === 'OTU Sample Information').value;
      const otuQuery = otuQueryTemplate.replace('1Sr2', `${sampleId}`);
      const otuEncodedQuery = encodeURIComponent(otuQuery);
      const otuResponse = await axios.get(`${BASE_URL}sparql?query=${otuEncodedQuery}`);

      if (otuResponse.data && otuResponse.data.results && otuResponse.data.results.bindings) {
        return processQueryResults(otuResponse.data.results.bindings);
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      throw error;
    }
  }, [processQueryResults]);

  const fetchSampleDetails = useCallback(async (sampleOrId) => {
    setIsError(false);
    let sampleId;
    let sample;

    if (typeof sampleOrId === 'object' && sampleOrId.identifier) {
      sample = sampleOrId;
      sampleId = sampleOrId.identifier;
      setSelectedSample(sample);
    } else if (typeof sampleOrId === 'string') {
      sample = await fetchAndSetSampleById(sampleOrId);
      sampleId = sampleOrId;
      if (!sample) {
        setSampleDetails(null);
        return;
      }
    } else {
      console.error('Invalid argument passed to fetchSampleDetails');
      setIsError(true);
      return;
    }

    try {
      const dnaDetails = await fetchDNAData(sampleId);
      const otuDetails = await fetchOTUData(sampleId);
      const soilDetails = await fetchSoilData(sampleId);
      setSampleDetails({
        dna: dnaDetails,
        otu: otuDetails,
        soil: soilDetails
      });
    } catch (error) {
      console.error('Error fetching sample details:', error.message);
      setIsError(true);
      setSampleDetails(null);
    }
  }, [fetchAndSetSampleById, fetchDNAData, fetchOTUData, fetchSoilData]);

  const executeQuery = useCallback(async (query) => {
    setIsError(false);
    try {
      const encodedQuery = encodeURIComponent(query);
      const response = await axios.get(`${BASE_URL}sparql?query=${encodedQuery}`);
      handleResults(response.data);
    } catch (error) {
      console.error('Error executing the query:', error.message);
      setIsError(true);
    }
  }, [handleResults]);

  const executeSampleQuery = useCallback(async (siteId) => {
    setIsError(false);
    try {
      const results = await fetchAndProcessSampleQuery(siteId);
      setSampleResults(results);
    } catch (error) {
      console.error('Error executing the sample query:', error.message);
      setIsError(true);
      setSampleResults(null);
    }
  }, [fetchAndProcessSampleQuery]);

  return {
    executeQuery,
    executeSampleQuery,
    fetchAndSetSampleById, 
    fetchSampleDetails,
    results,
    sampleResults,
    sampleDetails,
    selectedSample,
    isError
  };
}
