import React, { useState, useEffect } from 'react';
import Map from './Map';
import SampleDetails from './SampleDetails';
import QUERY_OPTIONS from '../data/queryOptions';
import { useSparqlQuery } from '../hooks/useSparqlQuery';

function HomePage() {
  const [queryResults, setQueryResults] = useState([]);

  const { executeQuery, executeSampleQuery, fetchSampleDetails, results, sampleResults, selectedSample, sampleDetails, isError } = useSparqlQuery();

  useEffect(() => {
    const listAllItemsQuery = QUERY_OPTIONS.find(option => option.name === 'List All Items').value;
    executeQuery(listAllItemsQuery);
  }, [executeQuery]);

  useEffect(() => {
    if (!isError && results.length > 0) {
      setQueryResults(results);
    }
  }, [results, isError]);  

  useEffect(() => {
    fetchSampleDetails('1Sr2');
  }, [fetchSampleDetails]);

  const handleSampleClick = (sample) => {
    fetchSampleDetails(sample);
  };

  return (
    <div>
      <Map 
        queryResults={queryResults} 
        isError={isError}
        onMarkerClick={executeSampleQuery}
        sampleResults={sampleResults}
        onSampleClick={handleSampleClick}
      />
      <SampleDetails sample={selectedSample}  sampleDetails={sampleDetails}/>
    </div>
  );
}

export default HomePage;
